.emoj_mainwrapper {
    padding: 3px 3px 10px;
    position: absolute;
    background-color: #ffff;
    border: 1px solid #e5e5e526;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 7%);
    max-width: 250px;
    border-radius: 5px;
    width: 250px;
    bottom: 0;
    left: 100%;
    z-index: 91;
}
.emoj_mainwrapper_holder{
    position: relative;
    width: fit-content;
    left: -5px;
    top: 4px;
}
.send-box form{
    margin-left: 0;
}
.emoj_input {
    width: 100%;
    border: 0;
    border-radius: 0;
    height: 40px;
    margin-bottom: 10px;
    outline: none !important;
    box-shadow: none !important;
    padding: 0px 15px;
    background-color: transparent;
    font-size: 14px;
    font-weight: normal;
    border-bottom: 1px solid #ececec;
}
.emoj_sectionsScroll ul {
    display: flex;
    align-items: center;
    justify-content: start;
    max-width: 100%;
    flex-wrap: wrap;
    margin-bottom: 20px;
    list-style: none;
    padding: 0;
}
.emoj_scrollableBox {
    max-height: 240px;
    overflow: auto;
    margin-bottom: 20px;
}
.emoj_title {
    margin-bottom: 10px;
    line-height: normal;
    color: #666666;
    font-size: 14px;
    padding: 0px 10px;
}
.emoj_icons {
    margin: 3px;
    font-size: 15px;
    /* color: #fff; */
}
.emoj_tabIcons {
    display: flex;
    flex-wrap: nowrap;
    /* padding: 5px; */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: space-around;
    background-color:#22cca6;
    border-top: 0;
    padding: 0;
    list-style: none;
}
.emoj_tabIcons li{
    padding: 2px;
}
li.isEmojiActive {
    background-color: #fff;
}
/* .emoj_mainwrapper[placement="left"] {
    left: auto;
    right: 100%;
} */
.emoj_mainwrapper[placement="left"] {
    left: auto;
    right: -25px;
    top: auto;
    bottom: 45px;
}
.emoj_mainwrapper[placement="right"] {
    left: 100%;
    right: auto;
}
.emoj_mainwrapper[placement="top"] {
    left: auto;
    right: calc(100% - 45px);
    bottom: 100%;
    top: auto;
}
.emoj_mainwrapper[placement="bottom"] {
    left: 0;
    right: auto;
    top: 100%;
    bottom: auto;
}